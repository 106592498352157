/**
 * 数值配置
 * 配置各种策划提供的数值
 *
 */
export default {
  depositDefaultOptions: [
    { get: 10000, price: 10000 },
    { get: 30000, price: 30000 },
    { get: 50000, price: 50000 },
    { get: 102000, hot: 0, more: 2, price: 100000 },
    { get: 204000, hot: 0, more: 2, price: 200000 },
    { get: 307500, hot: 0, more: 2.5, price: 300000 },
    { get: 515000, hot: 0, more: 3, price: 500000 },
    { get: 828000, hot: 0, more: 3.5, price: 800000 },
    { get: 1040000, hot: 0, more: 4, price: 1000000 },
    { get: 2080000, hot: 0, more: 4, price: 2000000 },
    { get: 3150000, hot: 0, more: 4.5, price: 3000000 },
    { get: 5250000, hot: 0, more: 5, price: 5000000 },
  ],
  firstRechargeAmount: 10000,
  firstRechargedWithdrawNeedBets: 20000,
  depositActivityAmount: 30000,
  depositActivityMore: 20,
  defaultDepositGot: 100,
  defaultDepositPrice: 99,
  minDepositAmount: 10000,
  maxDepositAmount: 2000000,
  withdrawFeePercent: 0,
  minWithdrawAmount: 30000,
  maxWithdrawAmount: 1200000,
  maxWithdrawDailyAmount: 6000000,
  inviteWheel: {
    targetAmount: 300000,
  },
  luckyDraw: {
    marqueeAmoundList: [120, 480, 1200, 300, 30, 120, 48, 600, 480, 300, 3000, 600, 480, 60, 300, 30, 4800, 48, 60, 12],
  },
  addHomeScreenRewardFrom: 9,
  addHomeScreenRewardTo: 1800,
  gasBurnRatio: 0,
  betGasRatio: 1,
  depositGasRatio: 0,
  useWithdrawLevel: 0,
};
